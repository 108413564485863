import { PeriodType } from 'src/constants';
import { toCurrency } from '../utils/currency';

export const getCancelledCost = async (canceledOption, allOptions, flags) => {
  const cancelledDate = new Date(canceledOption.createdAt);
  const cancelledPeriod = canceledOption?.reason?.type;

  const filteredData = allOptions.filter((item) => {
    const validFromDate = new Date(item.details.validFrom);
    const validUntilDate =
      flags?.pfEnableGraceCancellation &&
      cancelledPeriod === 'cancellationWithinTrialPeriod'
        ? new Date(item.details.validUntilGrace)
        : new Date(item.details.validUntil);

    return (
      item.details.returnDevice === true &&
      cancelledDate > validFromDate &&
      cancelledDate < validUntilDate
    );
  });

  let cancelledOptionToUse;
  if (filteredData?.length === 1) {
    cancelledOptionToUse = filteredData?.[0];
  } else {
    const sortedValidFromDates = filteredData?.sort(
      (a, b) =>
        new Date(a?.details?.validFrom).getTime() -
        new Date(b?.details?.validFrom).getTime()
    );

    cancelledOptionToUse = sortedValidFromDates?.[0];
  }

  if (cancelledOptionToUse?.name === PeriodType.TRIAL) {
    return {
      name: cancelledOptionToUse?.name,
      cost: toCurrency(
        cancelledOptionToUse?.outcomes[0]?.outcomes[0]?.value?.cost
      )
    };
  } else {
    return {
      name: cancelledOptionToUse?.name,
      cost: toCurrency(cancelledOptionToUse?.outcomes[0]?.value?.cost)
    };
  }
};
