import tokens from '@veneer/tokens';
import styled from 'styled-components';

const { color, layout, typography } = tokens;
const { lineHeight4 } = tokens.typography;

export const StepBlock = styled.div`
  font-family: ${typography.family0};
  display: flex;
  gap: ${layout.size6};
  @media (min-width: 640px) {
    align-items: center;
  }
`;

export const IconBlock = styled.div`
  height: 36px;
  width: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const Label = styled.p`
  position: absolute;
  top: 8px;
  font-size: ${typography.size2};
  line-height: ${typography.lineHeight2};
  color: #585858;
`;

export const ContentBlock = styled.div<{
  isComplete?: boolean;
  showBorder?: boolean;
}>`
  border-left: ${({ showBorder }) =>
    showBorder ? '2px solid #dbdbdb' : 'none'};
  margin-left: 18px;
  padding-left: 40px;
  margin-top: ${layout.size2};
  margin-bottom: ${layout.size3};
  font-size: ${typography.size2};
  line-height: ${typography.lineHeight3};
  color: ${({ isComplete }) => (isComplete ? color.gray9 : '#737373')};
`;

export const Title = styled.p`
  font-size: ${typography.size4};
  font-weight: 700;
  line-height: ${lineHeight4};
  color: ${color.gray12};
`;
