import styled from 'styled-components';
import tokens from '@veneer/tokens';
const {
  size5: layoutSize5,
  size4: layoutSize4,
  size2: layoutSize2,
  size1: layoutSize1
} = tokens.layout;
const { gray12 } = tokens.color;
const { size3, size1, lineHeight3, lineHeight2, family0 } = tokens.typography;
export const DevicesListItem = styled.div`
  @media (min-width: 500px) {
    display: flex;
    gap: ${layoutSize5};
  }
`;

export const DevicesListItemImage = styled.img`
  width: 200px;
  height: 220px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  @media (min-width: 500px) {
    width: 158px;
    height: 105px;
    margin: 0px;
  }
`;

export const DeviceListItemInfo = styled.div`
  margin-top: 8px;
  @media (min-width: 500px) {
    margin-top: ${layoutSize4};
  }
`;

export const CardTitle = styled.p`
  color: ${gray12};
  font-family: ${family0};
  font-size: ${size3};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight3};
  margin-bottom: ${layoutSize2};
`;
export const CardLabel = styled.p`
  color: ${gray12};
  font-family: ${family0};
  font-size: ${size1};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight2};
  margin-bottom: ${layoutSize1};
`;
