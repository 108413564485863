export function checkCancellationPeriod(optionsData) {
  const optionCancellationData = optionsData.subscription.cancellation;
  // Get the current date
  //const currentDate = new Date();//For now comment later may need
  const filteredData = optionCancellationData.filter((item) => {
    // const validFromDate = new Date(item.details.validFrom);
    // const validUntilDate = new Date(item.details.validUntil); //For now comment later may need

    return item.enabled === true && item.details.returnDevice === true;
  });
  return filteredData.length === 0
    ? { error: true, period: [] }
    : { error: false, period: filteredData };
}
