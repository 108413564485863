import {
  IconInkDoubleDrops,
  IconPaperReamOpen,
  IconPersonAssist,
  IconPrinterUpgrade,
  IconPrinterX,
  IconShieldCheckmark,
  IconTruck
} from '@veneer/core';
import React, { useEffect, useState } from 'react';
import {
  CardContainer,
  CardTitle,
  CardLabel,
  CardLabelText,
  CardListInfo,
  CardContainerList,
  Line
} from './Style';
import { useFlags } from 'launchdarkly-react-client-sdk';
const CancellatonBenefits = ({ t, props }) => {
  const [isInstantPaper, setIsInstantPaper] = useState<boolean>(false);
  const years = props?.inkPlanGraphqlDetails;
  const flags = useFlags();
  useEffect(() => {
    setIsInstantPaper(
      props?.rootSubscriptionData?.entities?.some(
        (item) => item.entityType === 'instant-paper'
      )
    );
  }, [props]);
  const isPaperVisible: boolean =
    flags?.enableManagePaperPostEnrollment && isInstantPaper;
  return (
    <CardContainer>
      <CardTitle>
        {t(
          'CancellatonBenefits.title',
          'If you cancel, you will lose access to:'
        )}
      </CardTitle>
      <Line />
      <CardContainerList>
        <CardListInfo>
          <IconPrinterX
            size={24}
            style={{ color: '#0076ad', flex: '0 0 auto' }}
          />
          <CardLabel>
            {t('CancellatonBenefits.CardLabel.4', 'Printing services')}
          </CardLabel>
        </CardListInfo>
        <CardListInfo>
          <IconInkDoubleDrops
            size={24}
            style={{ color: '#0076ad', flex: '0 0 auto' }}
          />
          <CardLabel>
            {t('CancellatonBenefits.CardLabel.1', 'Automatic ink delivery')}
          </CardLabel>
        </CardListInfo>
        {isPaperVisible && (
          <CardListInfo>
            <IconPaperReamOpen
              size={24}
              style={{ color: '#0076ad', flex: '0 0 auto' }}
            />
            <CardLabel>
              {t('CancellatonBenefits.CardLabel.5', 'Automatic paper delivery')}
            </CardLabel>
          </CardListInfo>
        )}
        <CardListInfo>
          <IconShieldCheckmark
            size={24}
            style={{ color: '#0076ad', flex: '0 0 auto' }}
          />
          <CardLabel>
            {t(
              'CancellatonBenefits.CardLabel.6',
              'Continuous printer coverage'
            )}
          </CardLabel>
        </CardListInfo>

        <CardListInfo>
          <IconTruck
            size={24}
            style={{ color: '#0076ad', flex: '0 0 auto' }}
          />
          <CardLabel>
            {t(
              'CancellatonBenefits.CardLabel.2',
              'Next-business-day printer replacement'
            )}
          </CardLabel>
        </CardListInfo>
        <CardListInfo>
          <IconPrinterUpgrade
            size={24}
            style={{ color: '#0076ad', flex: '0 0 auto' }}
          />
          <CardLabel>
            {t('CancellatonBenefits.CardLabel.3', {
              years,
              defaultValue: 'Option to upgrade after ${years} years'
            })}
          </CardLabel>
        </CardListInfo>
        <CardListInfo>
          <IconPersonAssist
            size={24}
            style={{ color: '#0076ad', flex: '0 0 auto' }}
          />
          <CardLabelText>
            {t('CancellatonBenefits.CardLabelText', '24/7 Pro live support')}
          </CardLabelText>
        </CardListInfo>
      </CardContainerList>
    </CardContainer>
  );
};

export default CancellatonBenefits;
