import React, { useEffect, useState, useMemo } from 'react';
import FirstComponent from './FirstComponent/FirstComponent';
import { MfePropsType } from 'src/types/mfeProps';
import { TranslatorFunctionType } from 'src/types/localization';
import { SubscriptionsStateClient } from 'src/services/SubscriptionsStateClient';
import { useLazyGetDeviceDetails } from 'src/services/ApolloClient';
import { EntityType, PeriodType, PrinterType } from 'src/constants';
import { getDeviceDetailsGraphql } from 'src/common/utils/getDeviceDetails';
import {
  calculateContractDuration,
  findOptionInkPlan
} from 'src/services/product';
import ErrorPage from './ErrorComponent';
import LoaderPage from './LoaderComponent';
import getPrinterData from '../../utils/printerUtility';
import { SubscriptionsClient } from 'src/api/SubscriptionClient';
import { useBillingCycle } from 'src/hooks/useBillingCycle';
import { checkCancellationPeriod } from '../../utils/remorseUtility';
import { toCurrency } from '../../utils/currency';
import {
  initialCancelState,
  CancelState
} from '../../types/initialCancelState';
import EmbeddedSurvey from './EmbeddedSurvey';
import { FulfillmentClient } from 'src/services/FulfillmentClient';
import { isDeviceShippedOrDelivered } from '../../hooks/isDeviceShippedOrDelivered';
import useAddress from 'src/hooks/useAddress';
import getLocalization from 'src/helpers/getLocalization';
import usePendingChanges from 'src/hooks/usePendingChanges';
import { useFlags } from 'launchdarkly-react-client-sdk';

const getSubscriptionId = (): string | undefined => {
  const pathname = window.location.pathname;
  /// get last element of the pathname
  const subscriptionId = pathname.split('/').slice(-1)[0];
  const isSubscriptionIdNumber = subscriptionId;
  return isSubscriptionIdNumber ? subscriptionId : '';
};
const PrinterCancellation = ({
  t,
  authProvider,
  stack,
  navigation,
  bizlogicId
}: MfePropsType & { t: TranslatorFunctionType }) => {
  const subscriptionId = getSubscriptionId();
  const [getDeviceDetails] = useLazyGetDeviceDetails();
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [error, setError] = useState<boolean>(false);
  const [eligibleCancellationOption, setEligibleCancellationOption] =
    useState<any>();
  const [subSuccess, setSubSuccess] = useState(false);
  const [optionSuccess, setOptionSuccess] = useState(false);
  const [graphqlSuccess, setGraphqlSuccess] = useState(false);
  const [subError, setSubError] = useState(false);
  const [graphqlError, setGraphqlError] = useState(false);
  const [inactivePrinter, setInactivePrinter] = useState(false);
  const [props, setProps] = useState<CancelState>(initialCancelState);
  const subscriptionClient = useMemo(
    () => new SubscriptionsClient(authProvider, stack, bizlogicId),
    [authProvider, stack, bizlogicId]
  );
  const fulfillmentOrdersAPIClient = useMemo(() => {
    return new FulfillmentClient(authProvider, stack);
  }, [authProvider, stack]);
  const flags = useFlags();
  const { hasCancellation, fetchPendingChanges } = usePendingChanges(
    authProvider,
    stack
  );

  useEffect(() => {
    if (subscriptionId && props?.printerSubscription?.entityId) {
      fetchPendingChanges(
        subscriptionId,
        '',
        props?.printerSubscription?.entityId
      );
    }
  }, [subscriptionId, props?.printerSubscription?.entityId]);

  useEffect(() => {
    async function fetchData() {
      const subscriptionsClient = new SubscriptionsStateClient(
        authProvider,
        stack
      );

      try {
        const { data } = await subscriptionsClient.getSubscriptionById(
          subscriptionId
        );
        setSubscriptionData(data);
        setSubSuccess(true);
        setSubError(false);
      } catch (e) {
        setSubError(true);
        setSubSuccess(false);
        console.log('Error', e);
      }
    }
    subscriptionId && fetchData();
  }, [authProvider, stack, subscriptionId]);

  useEffect(() => {
    const updateData = async () => {
      const allPrinters = subscriptionData?.entities?.filter(
        (entity) => entity.entityType === EntityType.PRINTER
      );
      let printerData;
      //
      if (allPrinters.length === 1) {
        printerData = allPrinters[0];
      } else {
        printerData = await getPrinterData(allPrinters);
      }
      setInactivePrinter(printerData?.state == 'inactive');
      const instantInkData = subscriptionData?.entities?.find(
        (item) => item?.entityType === EntityType.INSTANTINK
      );
      const parentSku = printerData?.product?.value?.parentProductSku;
      const productSku = printerData?.product?.value?.productSku;
      const instantInkProductSku = instantInkData?.product?.value?.productSku;

      setProps((prevData) => ({
        ...prevData,
        parentSKU: parentSku,
        productSKU: productSku,
        instantInkProductSKU: instantInkProductSku,
        printerSubscription: printerData,
        rootSubscriptionData: subscriptionData
      }));
    };

    if (subscriptionData) updateData();
  }, [subscriptionData]);

  useEffect(() => {
    const fetchDeviceDetails = async () => {
      /* istanbul ignore next */
      await getDeviceDetails({
        variables: { parentSKU: props.parentSKU },
        onError: () => {
          setGraphqlSuccess(false);
          setGraphqlError(true);
        },
        onCompleted: (response) => {
          const printerDetails = getDeviceDetailsGraphql(
            response,
            props.productSKU
          );
          const inkPlanDetails = findOptionInkPlan(
            props.subscriptionOption,
            props.instantInkProductSKU
          );
          const inkPlanGraphqlDetails = calculateContractDuration(response);
          setProps((prevData) => ({
            ...prevData,
            printerDetails: printerDetails,
            inkPlan: inkPlanDetails,
            inkPlanGraphqlDetails: inkPlanGraphqlDetails,
            isCissPrinter:
              printerDetails?.printerType === PrinterType.CISS_PRINTER
          }));
          setGraphqlSuccess(true);
          setGraphqlError(false);
        }
      });
    };
    if (
      props.parentSKU &&
      props.productSKU &&
      props.instantInkProductSKU &&
      props.subscriptionOption
    ) {
      fetchDeviceDetails();
    }
  }, [
    getDeviceDetails,
    props.parentSKU,
    props.productSKU,
    props.instantInkProductSKU,
    props.subscriptionOption
  ]);

  const [getBillingCycles, { billingDate, invoiceDate }] = useBillingCycle(
    authProvider,
    stack,
    props
  );

  useEffect(() => {
    if (props?.printerSubscription?.commerce?.value?.subscriptionId)
      getBillingCycles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.printerSubscription]);

  useEffect(() => {
    setProps((prevData) => ({
      ...prevData,
      cancellationBillingDate: billingDate,
      cancellationInvoicedDate: invoiceDate
    }));
  }, [billingDate, invoiceDate]);

  useEffect(() => {
    const entityState = props?.printerSubscription?.state;
    const isFlagEnabled = flags?.pfEnableNewReplacementFlow;
    if (isFlagEnabled === undefined) {
      return;
    }

    const isCancelled = isFlagEnabled
      ? ['deactivating', 'suspended', 'pending'].includes(entityState) &&
        hasCancellation
      : entityState === 'deactivating' ||
        (['suspended', 'pending'].includes(entityState) && hasCancellation);

    if (isCancelled) {
      //setIsOpen(true);
      setProps((prevData) => ({
        ...prevData,
        openSecond: true
      }));
    }
  }, [
    props?.printerSubscription,
    hasCancellation,
    flags?.pfEnableNewReplacementFlow
  ]);

  const openSecondComponent = () => {
    setProps((prevData) => ({
      ...prevData,
      openSecond: true,
      openSurvey: true
    }));
  };

  useEffect(() => {
    const setPriceAndRemorse = async () => {
      if (eligibleCancellationOption?.[0]?.name === PeriodType.TRIAL) {
        setProps((prevData) => ({
          ...prevData,
          cancellationRemorse: true,
          cancellationPeriodCost: toCurrency(
            eligibleCancellationOption?.[0]?.outcomes?.[0]?.outcomes?.[0]?.value
              ?.cost
          )
        }));
      } else {
        setProps((prevData) => ({
          ...prevData,
          cancellationRemorse: false,
          cancellationPeriodCost: toCurrency(
            eligibleCancellationOption?.[0]?.outcomes?.[0]?.value?.cost
          ),
          openSecond: false
        }));
      }
    };
    if (eligibleCancellationOption?.length > 0) {
      setPriceAndRemorse();
    }
    // else if (props?.printerSubscription) {
    //   setError(true);
    // }
  }, [eligibleCancellationOption, props?.printerSubscription, billingDate]);

  useEffect(() => {
    async function fetchData() {
      try {
        const subscriptionDetailsDataFetch =
          await subscriptionClient.getSubscriptionOptions(
            props?.printerSubscription?.subscriptionId,
            props?.printerSubscription?.entityId
          );

        const [isDeviceShipped] = await Promise.all([
          isDeviceShippedOrDelivered(
            fulfillmentOrdersAPIClient,
            props?.printerSubscription
          )
        ]);

        const periodData = checkCancellationPeriod(
          subscriptionDetailsDataFetch
        );
        if (periodData !== null) {
          setError(periodData.error);
          setEligibleCancellationOption(periodData.period);
        }
        setProps((prevData) => ({
          ...prevData,
          subscriptionOption: subscriptionDetailsDataFetch,
          cancellationPeriod: periodData.period,
          cancelOptionName: periodData?.period[0]?.name,
          isDeviceShipped: isDeviceShipped
        }));

        setOptionSuccess(true);
        setError(false);
      } catch (e) {
        setError(true);
        setOptionSuccess(false);
        console.log('Error', e);
      }
    }
    if (props?.printerSubscription?.subscriptionId !== undefined && bizlogicId)
      fetchData();
  }, [
    props?.printerSubscription,
    subscriptionClient,
    fulfillmentOrdersAPIClient,
    bizlogicId
  ]);

  const shippingAddressId = props?.printerSubscription?.shippingAddressId;

  const { address: addressData, loading: isAddressLoading } = useAddress(
    shippingAddressId,
    authProvider,
    stack
  );

  const { language } = getLocalization();

  const isSuccess =
    subSuccess && graphqlSuccess && !inactivePrinter && optionSuccess;

  const isError = subError || graphqlError || inactivePrinter || error;
  const isLoading = !isSuccess && !isError && isAddressLoading;

  return (
    <>
      {isLoading && <LoaderPage />}

      <EmbeddedSurvey
        subscriptionId={props?.printerSubscription?.subscriptionId}
        isInRemorse={props?.cancellationRemorse}
        deviceName={props?.printerDetails?.modelName}
        planDetails={props?.instantInkProductSKU}
        stack={stack}
        pagesPerMonth={props?.inkPlan?.pages}
        t={t}
        countryCode={addressData?.countryCode}
        languageCode={language}
      />

      {isError && (
        <ErrorPage
          props={props}
          {...{ t }}
          navigation={navigation}
        />
      )}
      {isSuccess && (
        <div>
          {
            <FirstComponent
              {...{
                t,
                authProvider,
                stack
              }}
              props={props}
              navigation={navigation}
              openSecondComponent={openSecondComponent}
              bizlogicId={bizlogicId}
            />
          }
        </div>
      )}
    </>
  );
};

export default PrinterCancellation;
