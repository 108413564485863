import moment from 'moment';

export const getPeriod = async (entityStartDate, toDate, type, t) => {
  const to = moment(toDate);
  const from = moment(entityStartDate);
  const diff = to.diff(from, 'months');

  if (diff === 0) {
    //One-Day BC Logic
    const days = to.diff(from, 'days');
    return days === 1 ? days + ' day' : days + ' days';
  } else if (diff === 1) {
    //Trial-Period BC Logic
    const days =
      type === 'validUntil'
        ? t('CancelModal.days', 'days')
        : t('CancelModal.days', 'days');
    return `30 ${days}`;
  } else {
    const months = t('CancelModal.months', 'months');
    return `${diff} ${months}`;
  }
};
