/* istanbul ignore file */

import { gql } from '@apollo/client';

export const GET_DEVICE_DETAILS = gql`
  query getDeviceDetails($parentSKU: String!) {
    products(filter: { sku: { eq: $parentSKU } }) {
      items {
        name
        sku
        ... on BundleProduct {
          hp_max_days_to_return
          items {
            title
            options {
              product {
                sku
                thumbnail {
                  url
                }
                ... on SimpleProduct {
                  hp_as_low_as_price
                  hp_product_type_label
                  hp_supply_type_label
                  hp_short_config_name
                }
                ... on VirtualProduct {
                  hp_short_config_name
                  hp_pages_per_month
                  hp_product_type_label
                  hp_product_type
                  hp_contract_duration
                  price {
                    regularPrice {
                      amount {
                        value
                      }
                    }
                  }
                }
              }
              label
            }
          }
        }
      }
    }
  }
`;
