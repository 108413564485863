export enum EntityType {
  PRINTER = 'iot-printer',
  INSTANTINK = 'instant-ink'
}
export enum PrinterType {
  CISS_PRINTER = 'inkTank'
}
export enum PeriodType {
  TRIAL = 'trialPeriod',
  RESCINDCANCEL = 'rescindCancel',
  STANDARD_YEAR1 = 'standardYear1',
  STANDARD_YEAR2 = 'standardYear2',
  STANDARD_YEAR1_RETURN_DEVICE = 'standardYear1ReturnDevice',
  STANDARD_YEAR2_RETURN_DEVICE = 'standardYear2ReturnDevice',
  STANDARD_AFTER_YEAR2_RETURN_DEVICE = 'standardAfterYear2ReturnDevice'
}
export enum ScreenMode {
  GRACE = 'Grace',
  COMMITMENT = 'Commitment',
  POSTCOMMITMENT = 'PostCommitment'
}
export enum AnalyticsClickEventsList {
  SCREEN_DISPLAY_FIRST = 'ScreenDisplayFirst',
  SCREEN_DISPLAY_SECOND = 'ScreenDisplaySecond',
  KEEP_PLAN = 'KeepPlanLink',
  SUPPORT_SUCCESS = 'SupportSuccess',
  CONFIRM_CANCELLATION_LINK = 'ConfirmCancellationLink',
  CHANGE_PLAN = 'ChangePlan',
  CANCELLATION_FEE_LINK = 'CancellationFee',
  RECYCLING_PAGE = 'RecyclingPage',
  RECYCLING_LINK = 'Recycling',
  RETURN_TO_DASHBOARD = 'ReturnToDashboard',
  CONTINUE = 'Continue',
  SUPPORT = 'Support',
  CANCELMODAL = 'CancelModal',
  FEEDBACK_SURVEY = 'CancelPlanSurvey',
  SUPPORT_FIRST = 'SupportFirst',
  SUPPORT_SECOND = 'SupportSecond'
}
