import React from 'react';
import RecyclingModal from '../RecyclingModal/RecyclingModal';
import CancelationModal from '../../CommonComponent/CancelModal/CancelModal';
import moment from 'moment';
import PrinterDetails from '../../CommonComponent/PrinterDetails';
import ReturnInstructions from '../ReturnInstructions';
import CancellationStep from '../CancellationStep';
import { StepContentText } from '../Styles';

const DeviceNotShipped = ({
  t,
  canceledDate,
  props,
  costValue,
  after10days,
  lastDayToReturnPrinter
}) => {
  const statusReturn = moment().isAfter(after10days);
  const statusComplete = moment().isAfter(lastDayToReturnPrinter);

  const returnInstructions = [
    t(
      'ReturnDevice.notification.cissprinter.remorse-title1',
      'Look for a FedEx email in your inbox. It may take up to a day to arrive. It will include pre-paid shipping instructions and QR code.'
    ),
    t(
      'ReturnDevice.notification.cissprinter.remorse-title2',
      'If you don’t see the email in your inbox after 24 hours, please check your spam folder.'
    )
  ];
  return (
    <>
      <CancellationStep
        isComplete
        title={`${canceledDate || ''}: ${t(
          'SecondComponent.ContentTitle.1',
          'Cancellation submitted'
        )}`}
        content={
          <>
            {t(
              'SecondComponent.ContentTitle.7',
              'The cancellation process has started, and you can no longer access HP All-In Plan services.'
            )}
          </>
        }
        label=""
      />
      <CancellationStep
        isComplete={statusReturn}
        title={`${canceledDate || ''}: ${t(
          'SecondComponent.ContentTitle.8',
          'Cancellation process in progress'
        )}`}
        label={`${t('SecondComponent.label.2', '2')}`}
        content={
          <>
            {t(
              'SecondComponent.ContentTitle.9',
              'Since you cancelled your order after the printer had already been dispatched, we were unable to halt its delivery. Once the printer arrives at your address, please follow the return instructions that will be sent to your email in the coming days. This will help you avoid any non-return fees.'
            )}
          </>
        }
      />
      <CancellationStep
        isComplete={statusComplete}
        title={` ${after10days || ''}: ${t(
          'SecondComponent.ContentTitle.10',
          'Printer must be submitted to FedEx by this date'
        )}`}
        label={`${t('SecondComponent.label.3', '3')}`}
        lastItem
        content={
          <>
            {t('SecondComponent.ContentTitle.11', {
              costValue,
              defaultValue: `You will be charged a ${costValue}* cancellation fee if you don’t submit the printer by this date. See more info about`
            })}
            <CancelationModal
              t={t}
              props={props}
            />
            <StepContentText isComplete={statusComplete}>
              {t(
                'SecondComponent.ContentTitle.12',
                'You are required to return the printer and ink cartridges via FedEx for'
              )}
              <RecyclingModal
                t={t}
                startDate={props?.printerSubscription?.entityStartDate}
                subscriptionId={props?.printerSubscription?.subscriptionId}
                trialPeriod={props?.cancellationPeriod}
              />
            </StepContentText>
            <PrinterDetails
              props={props}
              t={t}
            />
            <ReturnInstructions
              instructions={returnInstructions}
              t={t}
              cissPrinter={props?.isCissPrinter}
            />
          </>
        }
      />
    </>
  );
};

export default DeviceNotShipped;
