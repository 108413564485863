import React from 'react';
import { Line, RemorseSubTitle, RemorseTitle, TaxText } from './styles';
import DeviceNotShipped from '../DeviceNotShipped';
import DeviceShipped from '../DeviceShipped';
import { useFlags } from 'launchdarkly-react-client-sdk';

const CancellationStepper = ({
  inRemorse,
  t,
  canceledDate,
  isLoading,
  costValue,
  props,
  after10days,
  after10DaysOfBilling
}) => {
  const { enableBuyerRemorseCancellation } = useFlags();
  return (
    <>
      <RemorseTitle>
        {t('SecondComponent.CardLabelText', 'Here’s what happens next')}
      </RemorseTitle>
      <RemorseSubTitle>
        {t(
          'SecondComponent.CardText2',
          'We’ve also emailed you a copy of this schedule for reference.'
        )}
      </RemorseSubTitle>
      <Line />
      {props?.isDeviceShipped && (
        <DeviceShipped
          t={t}
          canceledDate={canceledDate}
          isLoading={isLoading}
          costValue={costValue}
          props={props}
          after10days={after10days}
          inRemorse={inRemorse}
          after10DaysOfBilling={after10DaysOfBilling}
        />
      )}
      {!props?.isDeviceShipped && enableBuyerRemorseCancellation && (
        <DeviceNotShipped
          t={t}
          canceledDate={canceledDate}
          props={props}
          costValue={costValue}
          after10days={after10days}
          lastDayToReturnPrinter={after10DaysOfBilling}
        />
      )}
      <TaxText>
        * {t('FirstComponent.Description.Content7', 'Taxes may be applied.')}
      </TaxText>
    </>
  );
};

export default CancellationStepper;
