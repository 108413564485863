import { PaymentIssue } from 'src/types/subscriptionState';

export const getHasCancellation = (
  pendingChangesData: PaymentIssue[],
  printerEntityId: string
) => {
  console.log(printerEntityId); //For now added later may delete
  if (!pendingChangesData?.length) return false;
  return pendingChangesData?.some(
    (item) =>
      item?.type === 'cancellation' &&
      (item?.reason?.type === 'cancellationAfterTrialPeriod' ||
        item?.reason?.type === 'cancellationWithinTrialPeriod' ||
        item?.reason?.type === 'cancellation')
    //item?.entityId === printerEntityId //For now commented later may need
  );
};
