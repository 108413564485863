import React from 'react';
import {
  StepperBlock,
  StepOne,
  StepLine,
  HrLine,
  Card,
  Content,
  ContentTitle,
  CardOneContainer,
  StepOneContent,
  Label,
  StepThreeContent,
  StepThreeContentText,
  StepTwoTitle
} from '../SecondComponent/Styles';
import { IconCheckmarkCircle, IconCircle } from '@veneer/core';
import CancelationModal from '../CommonComponent/CancelModal/CancelModal';
import { DeviceComponent } from './DeviceComponent';
import RecyclingModal from './RecyclingModal/RecyclingModal';
import moment from 'moment';

export const NotInRemosePeriod = ({
  canceledDate,
  t,
  isLoading,
  lastDayToReturnPrinter,
  filteredCost,
  props,
  isInRemorse
}) => {
  const statusReturn = moment().isAfter(props?.cancellationInvoicedDate);
  const statusComplete = moment().isAfter(lastDayToReturnPrinter);
  return (
    <StepperBlock>
      <StepOne>
        <StepLine>
          <IconCheckmarkCircle
            size={36}
            filled
          />
          <HrLine />
        </StepLine>
        <Card>
          <Content>
            <ContentTitle>
              {canceledDate}
              {':'}{' '}
              {t('SecondComponent.ContentTitle.1', 'Cancellation submitted')}
            </ContentTitle>
            <CardOneContainer>
              <StepOneContent>
                {t(
                  'SecondComponent.StepOneContent1',
                  'The cancellation process is started. Your access to HP All-In Plan services will end on '
                )}
                {props?.cancellationBillingDate}
                {'.'}
              </StepOneContent>
            </CardOneContainer>
          </Content>
        </Card>
      </StepOne>
      <StepOne>
        <StepLine>
          {statusReturn ? (
            <>
              <IconCheckmarkCircle
                size={36}
                filled
              />
            </>
          ) : (
            !isLoading && (
              <>
                <IconCircle
                  size={36}
                  style={{ color: '#666' }}
                />
                <Label>{t('SecondComponent.label.2', '2')}</Label>
              </>
            )
          )}
          <HrLine />
        </StepLine>

        <Card>
          <StepThreeContent>
            <>
              <ContentTitle>
                {props?.cancellationInvoicedDate}
                {': '} {filteredCost}*
                {t(
                  'SecondComponent.ContentTitle.4',
                  ' Cancellation fee will be charged'
                )}
              </ContentTitle>
              <StepThreeContentText isComplete={statusReturn}>
                {t(
                  'SecondComponent.StepTwoTitle.12',
                  'The printer will stop working on this date (end of billing cycle).'
                )}{' '}
                {t('CancelModal.more-info1', 'See more info about')}
                <CancelationModal
                  t={t}
                  props={props}
                />
              </StepThreeContentText>
            </>
          </StepThreeContent>
        </Card>
      </StepOne>
      <StepOne>
        <StepLine>
          {statusComplete ? (
            <>
              <IconCheckmarkCircle
                size={36}
                filled
              />
            </>
          ) : (
            !isLoading && (
              <>
                <IconCircle
                  size={36}
                  style={{ color: '#666' }}
                />
                <Label>{t('SecondComponent.label.3', '3')}</Label>
              </>
            )
          )}
        </StepLine>
        <Card>
          <StepThreeContent>
            {props?.isCissPrinter ? (
              <ContentTitle>
                {t(
                  'SecondComponent.ContentTitle.6',
                  'Return printer via FedEx for recycling'
                )}
              </ContentTitle>
            ) : (
              <ContentTitle>
                {t(
                  'SecondComponent.ContentTitle.5',
                  'Return printer and ink cartridges via FedEx for recycling'
                )}
              </ContentTitle>
            )}
            <StepTwoTitle
              isComplete={statusComplete}
              data-testid="return-info-not-cancelled-not-inremorse"
            >
              {t('SecondComponent.StepTwoTitle.11', 'See more info about ')}

              <RecyclingModal
                t={t}
                startDate={props?.printerSubscription?.entityStartDate}
                subscriptionId={props?.printerSubscription?.subscriptionId}
                trialPeriod={props?.cancellationPeriod}
              />
            </StepTwoTitle>
            <DeviceComponent
              t={t}
              props={props}
              isInRemorse={isInRemorse}
            />
          </StepThreeContent>
        </Card>
      </StepOne>
    </StepperBlock>
  );
};
