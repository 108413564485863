import React from 'react';
import { captureClickEvents } from '../../../../services/DataCollection/clickActions';
import { AnalyticsClickEventsList } from '../../../../constants';

import PrinterDetails from '../../CommonComponent/PrinterDetails';
import { internalURLs } from '../../../configs/internal-link';
import CancellatonBenefits from '../../CommonComponent/CancellatonBenefits';
import { Button } from '@veneer/core';
import CancelationModal from '../../CommonComponent/CancelModal/CancelModal';
import useModifiedUrl from 'src/hooks/useModifiedUrl';
import {
  ButtonContainer,
  CancelDeviceInfoWrapper,
  LeftBlock,
  TaxText,
  Title
} from './styles';
import CancellationInfoText from './CancellationInfoText';

const CancelDeviceInfo = ({ t, props, navigation, openSecond, loading }) => {
  const cancelationModal = (
    <CancelationModal
      t={t}
      props={props}
    />
  );
  const finalPath = useModifiedUrl(internalURLs.PrinterOverviewURL);

  const handleConfirmCancellation = () => {
    openSecond();
    captureClickEvents(
      AnalyticsClickEventsList.CONFIRM_CANCELLATION_LINK,
      props?.printerSubscription?.entityStartDate,
      props?.printerSubscription?.subscriptionId,
      props?.cancellationPeriod
    );
  };

  const handleKeepSubscription = () => {
    navigation.push(finalPath);
    captureClickEvents(
      AnalyticsClickEventsList.KEEP_PLAN,
      props?.printerSubscription?.entityStartDate,
      props?.printerSubscription?.subscriptionId,
      props?.cancellationPeriod
    );
  };

  return (
    <CancelDeviceInfoWrapper>
      <LeftBlock>
        <Title>
          {t('HeaderComponent.title', 'Cancel HP All-In Plan for this device')}
        </Title>
        <PrinterDetails
          props={props}
          t={t}
        />
        <CancellationInfoText
          t={t}
          props={props}
          cancelationModal={cancelationModal}
        />
        {props?.isDeviceShipped && (
          <TaxText>
            *{' '}
            {t('FirstComponent.Description.Content7', 'Taxes may be applied.')}
          </TaxText>
        )}
        {!loading && (
          <ButtonContainer>
            <Button onClick={handleConfirmCancellation}>
              {t(
                'FirstComponent.button.Confirm-Cancellation',
                'Confirm Cancellation'
              )}
            </Button>
            <Button
              appearance="secondary"
              onClick={handleKeepSubscription}
            >
              {t(
                'FirstComponent.button.Keep-Subscription',
                'Keep Subscription'
              )}
            </Button>
          </ButtonContainer>
        )}
      </LeftBlock>
      <CancellatonBenefits
        t={t}
        props={props}
      />
    </CancelDeviceInfoWrapper>
  );
};

export default CancelDeviceInfo;
