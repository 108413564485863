import { IconCheckmarkCircle, IconCircle } from '@veneer/core';
import React, { ReactNode } from 'react';
import { ContentBlock, IconBlock, Label, StepBlock, Title } from './styles';

interface CancellationStep {
  isComplete: boolean;
  title: string;
  content: ReactNode;
  label: string;
  lastItem?: boolean;
}

const CancellationStep = (props: CancellationStep) => {
  const { isComplete, title, content, label, lastItem = false } = props;
  return (
    <>
      <StepBlock>
        <IconBlock>
          {isComplete ? (
            <IconCheckmarkCircle
              filled
              size={36}
            />
          ) : (
            <>
              <IconCircle
                size={36}
                style={{ color: '585858' }}
              />
              <Label>{label}</Label>
            </>
          )}
        </IconBlock>
        <Title>{title}</Title>
      </StepBlock>
      <ContentBlock
        isComplete={isComplete}
        showBorder={isComplete && !lastItem}
      >
        {content}
      </ContentBlock>
    </>
  );
};

export default CancellationStep;
