import styled from 'styled-components';
import { tokens } from '@veneer/tokens';
const {
  size2: layoutSize2,
  size3: layoutSize3,
  size0: layoutSize0,
  size4: layoutSize4,
  size5: layoutSize5,
  cornerRadius3
} = tokens.layout;
const { lineHeight1, size4, lineHeight4, lineHeight2, size1, family0 } =
  tokens.typography;
const { gray12, black, gray9, hpBlue7, vividSkyBlue0 } = tokens.color;

export const Card = styled.div`
  padding: ${layoutSize0} ${layoutSize2} ${layoutSize0} ${layoutSize3};
  align-items: flex-start;
  align-self: stretch;
  border-radius: ${cornerRadius3};
  background: ${vividSkyBlue0};
  @media (min-width: 500px) {
    display: flex;
  }
`;

export const Title = styled.p`
  font-family: ${family0};
  font-size: ${layoutSize4};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight4};
  color: ${gray12};
`;
export const Container = styled.div`
  margin-left: ${layoutSize5};
  @media (min-width: 1028px) {
    margin-left: 8px;
  }
`;
export const Step = styled.div``;
export const Icon = styled.div`
  display: inline-flex;
  align-items: center;
  position: relative;
  gap: 3px;
`;
export const StepIcon = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: ${layoutSize4};
`;
export const AlignIcon = styled.div`
  display: inline-flex;
  align-items: center;
  position: relative;
  gap: ${layoutSize3};
`;

export const IconNumber = styled.p`
  font-family: ${family0};
  font-size: ${size4};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight4};
  color: ${black};
`;
export const Content = styled.p`
  font-family: ${family0};
  font-size: ${size1};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight2};
  color: ${gray12};
`;
export const PackageContent = styled.p`
  font-family: ${family0};
  font-size: ${size1};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight2};
  color: ${gray9};
`;

export const CardContent = styled.div`
  display: flex;
  padding: ${layoutSize3} ${layoutSize3} ${layoutSize3} ${layoutSize0};
  flex-direction: column;
  align-items: flex-start;
  gap: ${layoutSize2};
  flex: 1 0 0;
`;
export const CardIcon = styled.div`
  display: flex;
  padding: ${layoutSize3} ${layoutSize3} ${layoutSize0} ${layoutSize0};
  align-items: flex-start;
`;
export const Link = styled.a`
  color: ${hpBlue7};
  text-decoration-line: underline;
  word-break: break-all;
`;
export const CardText = styled.li`
  font-family: ${family0};
  font-size: ${layoutSize3};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight1};
  color: ${gray9};
  text-indent: -1.5em;
  margin: 4px;
  @media (min-width: 1028px) {
    text-indent: 0em;
    margin: 0px;
  }
`;
