import { IconInfo } from '@veneer/core';
import React from 'react';
import {
  CardContainer,
  Container,
  ContentBlock,
  IconBlock,
  Instruction,
  Link,
  Title
} from './styles';
import { redirectURLs } from 'src/components/configs/external-link';

interface ReturnInstructionsProps {
  instructions: string[];
  t: any;
  cissPrinter: boolean;
}

const ReturnInstructions = ({
  instructions,
  t,
  cissPrinter
}: ReturnInstructionsProps) => {
  return (
    <Container>
      <IconBlock>
        <IconInfo
          size={24}
          filled
          color="hpBlue7"
        />
      </IconBlock>
      <ContentBlock>
        <Title>
          {cissPrinter
            ? t(
                'ReturnDevice.notification.cissprinter.title',
                'How to return the printer'
              )
            : t(
                'ReturnDevice.notification.nonCissPrinter.title',
                'How to return the printer and ink cartridges'
              )}
        </Title>
        <CardContainer>
          {instructions.map((item) => (
            <Instruction key={item}>{item}</Instruction>
          ))}
          {cissPrinter && (
            <Instruction>
              {t(
                'ReturnDevice.notification.cissprinter.remorse-title3',
                'To prevent ink leaks or printer damage, please visit'
              )}{' '}
              <Link
                href={redirectURLs.transportSmartTank}
                target="_blank"
                rel="noreferrer"
              >
                hp.com/support/transportsmarttank
              </Link>
            </Instruction>
          )}
        </CardContainer>
      </ContentBlock>
    </Container>
  );
};

export default ReturnInstructions;
