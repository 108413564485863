import styled from 'styled-components';
import { tokens } from '@veneer/tokens';
import { Modal } from '@veneer/core';
const { black, gray12, hpBlue7, gray6, gray9, white } = tokens.color;
const {
  size1,
  size0,
  size5,
  size2,
  family0,
  lineHeight2,
  lineHeight3,
  lineHeight1,
  lineHeight5,
  size4
} = tokens.typography;
const {
  size4: layout4,
  size2: layout2,
  cornerRadius2,
  size1: layout1,
  size6: layout6,
  size0: layout0,
  smMax
} = tokens.layout;
interface StyledCardProps {
  $currentStatusFlag?: boolean;
}
export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;
export const PrinterCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${layout2};
  margin-bottom: ${layout1};
`;
export const CardTitleAlignment = styled.div`
  margin-bottom: ${size4};
  @media (min-width: 360px) {
    display: flex;
    flex-direction: column;
  }
  @media (min-width: 500px) {
    display: block;
  }
`;
export const ModalBox = styled('div')<StyledCardProps>`
  padding: ${layout4};
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  gap:${(props) => (props.$currentStatusFlag ? layout0 : layout2)}
  align-self: stretch;
  border-radius: ${cornerRadius2};
 
  border:${(props) =>
    props.$currentStatusFlag
      ? '2px solid var(--Light-Primary, #0076AD)'
      : '1px solid var(--Light-Stroke, #D9D9D9)'};

  background: ${(props) =>
    props.$currentStatusFlag ? 'rgba(0, 118, 173, 0.10)' : white};

 @media (min-width:500px){
   display: flex;
 }
`;
export const CurrentStatusLabel = styled.div`
  display: flex;
  max-width: 123px;
  color: ${white};
  padding: ${layout1} ${layout1} ${layout1} ${layout2};
  align-items: center;
  border-radius: 0px ${layout2};
  background: var(--Light-Primary, #0076ad);
  margin-left: auto;
  margin-top: -17px;
  margin-right: -17px;
  @media (min-width: 500px) {
    max-width: 256px;
  }
`;
export const PrinterName = styled.p`
  font-size: ${size1};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight2};
  color: ${gray12};
`;
export const ModalContent = styled('p')<StyledCardProps>`
  display: flex;
  flex-direction: row;
  font-size: ${size1};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight2};
  color: #${black};
  align-items: center;
  gap: ${layout1};
  justify-content: ${(props) =>
    props.$currentStatusFlag ? 'flex-start' : 'space-between'};
  margin-top: ${(props) => (props.$currentStatusFlag ? '-16px' : '0px')};
  @media screen and (max-width: ${smMax}) {
    margin-top: 0px;
  }
`;
export const ModalSubText = styled.p`
  font-size: ${size0};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight1};
  color: ${gray12};
  margin-top: 8px;
`;
export const ModalSubContent = styled.p`
  display: flex;
  flex-direction: row;
  font-size: ${size0};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight1};
  color: #${black};
`;
export const CancelationDays = styled.p`
  font-weight: 700;
  line-height: ${lineHeight1};
`;
export const Price = styled.p`
  font-weight: 400;
  line-height: ${lineHeight2};
`;
export const SubText = styled.p`
  font-size: ${size1};
  font-style: normal;

  color: #${black};
  margin-bottom: ${size5};
  margin-top: ${size2};
`;
export const CancelModalCardLabel = styled.span`
  color: ${hpBlue7};
  font-family: ${family0};
  font-size: ${size2};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight3};
  cursor: pointer;
  text-decoration: underline;
  margin-left: 4px;
  margin-right: 4px;
`;

export const CancelModalCardTitle = styled.span`
  color: ${black};
  margin-bottom: 12px;
  margin-top: 20px;

  font-family: ${family0};
  font-size: ${size5};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight5};
  @media (min-width: 500px) {
    margin-left: 20px;
    margin-bottom: 8px;
    margin-top: 0px;
  }
`;
export const CancelModalIcon = styled.div`
  display: inline-flex;
  align-items: center;
  position: relative;
  gap: 3px;
`;
export const ModalContentText = styled.div`
  color: ${black};
  font-family: ${family0};
  font-size: ${size0};
  font-style: normal;
  font-weight: 700;
  line-height: ${lineHeight1};
  margin-top: 44px;
`;
export const ModalContentLabel = styled.div`
  color: ${gray6};
  font-family: ${family0};
  font-size: ${size0};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight1};
  margin: ${layout6} ${layout0};
`;
export const ModalCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${layout2};
`;
export const ModalInformation = styled.div`
  color: ${gray9};
  font-size: ${size0};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight1};
`;
export const Link = styled.a`
  color: ${hpBlue7};
  text-decoration-line: underline;
`;
export const CancellationModal = styled(Modal)`
  & > div > div {
    max-width: calc(497px + 48px + 48px);
  }
`;
