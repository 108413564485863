import React, { useEffect, useState } from 'react';
import {
  ModalContent,
  CancelationDays,
  Price,
  ModalBox,
  ModalSubText,
  ModalCard,
  CurrentStatusLabel
} from './Styles';
import { toCurrency } from '../../../../utils/currency';
import { generateCancellationFeeList } from 'src/utils/generateCancellationFeeList';
import { PeriodType } from 'src/constants';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getLastLegalToDuration } from 'src/utils/getLastLegalToDuration';

const CancelModalFeeCard = ({
  t,
  cancelOptionName,
  cancellationOptions,
  startDate
}) => {
  const [result, setResult] = useState([]);
  const [lastLegalToDuration, setLastLegalToDuration] = useState('');
  const { pfEnableCancelFeeAfterLease } = useFlags();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const feeResult = await generateCancellationFeeList(
          cancellationOptions,
          startDate,
          cancelOptionName,
          t
        );

        setResult(feeResult);
        if (pfEnableCancelFeeAfterLease) {
          const lastDuration = await getLastLegalToDuration(feeResult);
          setLastLegalToDuration(lastDuration);
        }
      } catch (error) {
        console.error('Error fetching cancellation fee data:', error);
      }
    };

    fetchData();
  }, [t, cancelOptionName, cancellationOptions, startDate]);

  return (
    <ModalCard>
      {result.map((option, index) => {
        const isTrialPeriod = option.name === PeriodType.TRIAL;

        return (
          <ModalBox
            key={index}
            $currentStatusFlag={option.currentSelection}
          >
            {option.currentSelection && (
              <CurrentStatusLabel>
                {t('CancelModal.CurrentStatus', 'Your current status')}
              </CurrentStatusLabel>
            )}
            <ModalContent $currentStatusFlag={option.currentSelection}>
              <CancelationDays>
                {isTrialPeriod
                  ? `${t('CancelModal.Up_to', 'Up to')} ${option.toDuration}:`
                  : `${t('CancelModal.after', 'After')} ${
                      option.fromDuration
                    } ${t('CancelModal.And_up_to', 'and up to')} ${
                      option.toDuration
                    }:`}
              </CancelationDays>
              <Price>
                {option.cancelationFee !== '0'
                  ? `${toCurrency(option?.cancelationFee)}*`
                  : t('CancelModal.No-fee', 'No fee')}
              </Price>
            </ModalContent>
            {isTrialPeriod && (
              <ModalSubText>
                {t(
                  'CancelModal.ModalContent6',
                  '  With the return of the undamaged printer, the cancellation fee is waived.'
                )}
              </ModalSubText>
            )}
          </ModalBox>
        );
      })}

      {lastLegalToDuration && pfEnableCancelFeeAfterLease && (
        <ModalBox
          key="last-legal"
          $currentStatusFlag={false}
        >
          <ModalContent $currentStatusFlag={false}>
            <CancelationDays>
              {`${t('CancelModal.after', 'After')} ${lastLegalToDuration}:`}
            </CancelationDays>
            <Price>{t('CancelModal.No-fee', 'No fee')}</Price>
          </ModalContent>
        </ModalBox>
      )}
    </ModalCard>
  );
};

export { CancelModalFeeCard };
