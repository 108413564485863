import tokens from '@veneer/tokens';
import styled from 'styled-components';

const { color, layout, typography } = tokens;

const { lineHeight1, family0 } = tokens.typography;
const { gray9 } = tokens.color;
const { size3: layoutSize3 } = tokens.layout;
export const Container = styled.div`
  padding: ${layout.size3};
  border-radius: ${layout.cornerRadius3};
  background-color: ${color.vividSkyBlue0};
  gap: ${layout.size3};
  margin-top: ${layout.size5};
  @media (min-width: 500px) {
    display: flex;
  }
`;

export const IconBlock = styled.div``;

export const ContentBlock = styled.div``;

export const Title = styled.p`
  color: ${color.gray12};
  font-size: ${typography.size3};
  line-height: ${typography.lineHeight2};
  margin-bottom: ${layout.size2};
`;

export const Instruction = styled.li`
  font-family: ${family0};
  font-size: ${layoutSize3};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight1};
  color: ${gray9};
  text-indent: -1.5em;
  margin: 4px;
  @media (min-width: 1028px) {
    text-indent: 0em;
    margin: 0px;
  }
`;

export const Link = styled.a`
  color: ${color.hpBlue7};
  text-decoration: underline;
  word-break: break-all;
`;
export const CardContainer = styled.div`
  margin-left: 20px;
  @media (min-width: 1028px) {
    margin-left: 8px;
  }
`;
