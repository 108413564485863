import { getPeriod } from './getPeriod';
import { PeriodType } from '../constants';

export const generateCancellationFeeList = async (
  cancellationOptions,
  startDate,
  cancelOptionName,
  t
) => {
  const options = await Promise.all(
    cancellationOptions
      .filter(
        (item) =>
          item.name !== PeriodType.RESCINDCANCEL && item.details.returnDevice
      )
      .map(async (item) => {
        const { name, details } = item;
        const cancelationFee =
          item.name === PeriodType.TRIAL
            ? '0'
            : item.outcomes?.[0]?.value?.cost;

        const fromDuration = await getPeriod(
          startDate,
          details?.validFrom,
          'validFrom',
          t
        );
        const toDuration = await getPeriod(
          startDate,
          details?.validUntil,
          'validUntil',
          t
        );

        return {
          name,
          fromDuration,
          toDuration,
          cancelationFee,
          currentSelection: name === cancelOptionName
        };
      })
  );

  return options;
};
