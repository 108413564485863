import React from 'react';
import {
  StepperBlock,
  StepOne,
  StepLine,
  HrLine,
  Card,
  Content,
  ContentTitle,
  CardOneContainer,
  StepOneContent,
  Label,
  StepThreeContent,
  StepThreeContentText,
  StepTwoContentText
} from '../SecondComponent/Styles';
import { IconCheckmarkCircle, IconCircle } from '@veneer/core';
import CancelationModal from '../CommonComponent/CancelModal/CancelModal';
import { DeviceComponent } from './DeviceComponent';
import moment from 'moment';
import RecyclingModal from './RecyclingModal/RecyclingModal';

export const RemorsePeriod = ({
  t,
  canceledDate,
  isLoading,
  filteredCost,
  props,
  after10days,
  isInRemorse
}) => {
  const statusReturn = moment().isAfter(after10days);

  return (
    <StepperBlock>
      <StepOne>
        <StepLine>
          <IconCheckmarkCircle
            size={36}
            filled
          />
          <HrLine />
        </StepLine>
        <Card>
          <Content>
            <ContentTitle>
              {canceledDate}
              {':'}{' '}
              {t('SecondComponent.ContentTitle.1', 'Cancellation submitted')}
            </ContentTitle>
            <CardOneContainer>
              <>
                <StepOneContent>
                  {t(
                    'SecondComponent.StepOneContent2',
                    'The cancellation process has started, and you can no longer access HP All-In Plan services.'
                  )}
                </StepOneContent>
              </>
            </CardOneContainer>
          </Content>
        </Card>
      </StepOne>
      <StepOne>
        <StepLine>
          {statusReturn ? (
            <>
              <IconCheckmarkCircle
                size={36}
                filled
              />
            </>
          ) : (
            !isLoading && (
              <>
                <IconCircle
                  size={36}
                  style={{ color: '#666' }}
                />
                <Label>{t('SecondComponent.label.2', '2')}</Label>
              </>
            )
          )}
        </StepLine>
        <Card>
          <StepThreeContent>
            <ContentTitle>
              {after10days}
              {':'}{' '}
              {t(
                'SecondComponent.ContentTitle.2',
                'Printer must be submitted to FedEx by this date'
              )}
            </ContentTitle>
            <>
              <StepThreeContentText isComplete={statusReturn}>
                {t('SecondComponent.StepTwoTitle.1', 'You will be charged a ')}
                {filteredCost}*{' '}
                {t(
                  'SecondComponent.StepTwoTitle.2',
                  'cancellation fee if you don’t submit the printer by this date.'
                )}{' '}
                {t('CancelModal.more-info1', 'See more info about')}
                <CancelationModal
                  t={t}
                  props={props}
                />
              </StepThreeContentText>
              <StepTwoContentText isComplete={statusReturn}>
                {props?.isCissPrinter
                  ? t(
                      'SecondComponent.StepTwoTitle.14-cissprinter',
                      'You are required to return the printer via FedEx for'
                    )
                  : t(
                      'SecondComponent.StepTwoTitle.14',
                      'You are required to return the printer and ink cartridges via FedEx for'
                    )}
                <RecyclingModal
                  t={t}
                  startDate={props?.printerSubscription?.entityStartDate}
                  subscriptionId={props?.printerSubscription?.subscriptionId}
                  trialPeriod={props?.cancellationPeriod}
                />
              </StepTwoContentText>
            </>
            <DeviceComponent
              t={t}
              props={props}
              isInRemorse={isInRemorse}
            />
          </StepThreeContent>
        </Card>
      </StepOne>
    </StepperBlock>
  );
};
